@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500&display=swap");
@import "normalize";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#InvoiceBuilderModule-container,
#InvoiceBuilder-root {
  font-family: Poppins, Arial, sans-serif;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

//iconbutton
.MuiIconButton-root:focus {
  background-color: unset;
}

//styled scrollbar
.ib_scroll_bar {
  height: 100%;
  overflow-y: auto;
}
.ib_scroll_bar::-webkit-scrollbar-track {
  box-shasdow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #112c4f;
  opacity: 1;
}
.ib_scroll_bar::-webkit-scrollbar {
  width: 6px;
}
.ib_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5bd7;
}

.ib_scroll_bar_light {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.ib_scroll_bar_light::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(206, 206, 206, 0.3);
  border-radius: 10px;
  background-color: #f3f3f3;
  opacity: 1;
}
.ib_scroll_bar_light::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ib_scroll_bar_light::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(192, 192, 192, 0.3);
  background-color: #d7d7d7;
}

.ib_xscroll_bar {
  height: 100%;
  overflow-x: auto;
}
.ib_xscroll_bar::-webkit-scrollbar-track {
  -webkit-box-shasdow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--dark_blue);
}

.ib_xscroll_bar-80::-webkit-scrollbar-track {
  margin-top: 30px;
}
.ib_xscroll_bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ib_xscroll_bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ib_xscroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--purple);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multiline_truncate {
  width: 100%;
  max-width: 320px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preview {
  strong {
    font-weight: 700;
  }
  ul {
    list-style: initial;

    & li {
      list-style-type: inherit;
    }
  }

  ol {
    & li {
      list-style-type: inherit;
    }
  }
}
